

































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminUsers } from '@/store/admin/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetUsers, dispatchDeleteUser } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public loading = true;
  public confirmDeleteDialog = false;
  public userToBeDeleted = '';
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Is Active',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    {
      text: 'Is Superuser',
      sortable: true,
      value: 'is_superuser',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];
  get users() {
    return readAdminUsers(this.$store);
  }

  get userSelf() {
    return readUserProfile(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.loading = false;
  }

  public async deleteUser() {
    await dispatchDeleteUser(this.$store, this.userToBeDeleted);
  }

  public async toggleConfirmDelete(id: string) {
    this.userToBeDeleted = id;
    this.confirmDeleteDialog = !this.confirmDeleteDialog;
  }

  routeEditUser(id: string) {
    this.$router.push({ name: 'main-admin-users-edit', params: { id } });
  }
}
